<template>
	<div
		class="input-icon"
		:class="{ shake, error, success, edit: state === 'EDITING' }"
	>
		<input
			type="text"
			class="input-field"
			:placeholder="placeholder"
			:value="modelValue"
			@input="input"
			@focus="focus"
			@focusout="focusOut"
			@keydown="keydown"
			:disabled="disabled"
			ref="inputElement"
			v-if="!mask"
		/>
		<input
			type="text"
			class="input-field"
			:placeholder="placeholder"
			:value="modelValue"
			@input="input"
			@focus="focus"
			@focusout="focusOut"
			@keydown="keydown"
			:disabled="disabled"
			v-mask="mask"
			ref="inputElement"
			v-if="mask"
		/>
		<div class="icon-wrap" @click="enter">
			<svg width="20" height="16" viewBox="0 0 20 16" fill="none" class="icon">
				<path
					d="M4.842 13.784L1.825 10.241H1.825C0.724919 8.94951 0.724919 7.05049 1.825 5.759L4.842 2.216V2.216C5.49859 1.44435 6.46081 0.9998 7.474 1H15.543V1C17.4522 1 19 2.54775 19 4.457C19 4.457 19 4.457 19 4.457V11.543V11.543C19 13.4523 17.4522 15 15.543 15H7.474V15C6.46081 15.0002 5.4986 14.5556 4.842 13.784V13.784Z"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
				<path
					d="M13.882 6.51294L10.907 9.48694L9.11801 7.69994"
					stroke="currentColor"
					stroke-width="1.5"
					stroke-linecap="round"
					stroke-linejoin="round"
				/>
			</svg>
		</div>
	</div>
</template>
<script lang="ts">
import { useInput } from '@/composables/useInput'
import { defineComponent } from 'vue'
import { mask } from 'vue-the-mask'

export default defineComponent({
	props: {
		placeholder: {
			type: String,
			default: ''
		},
		modelValue: {
			type: String,
			default: ''
		},
		disabled: {
			type: Boolean,
			default: false
		},
		mask: {
			type: [String, Array],
			default: ''
		}
	},
	directives: {
		mask
	},
	emits: ['update:modelValue', 'enter'],
	setup(_, ctx) {
		return useInput(ctx)
	}
})
</script>
<style scoped>
.input-icon {
	background: #1f222b;
	border-radius: 8px;
	height: 54px;
	width: 100%;
	box-sizing: border-box;
	position: relative;
	z-index: 1;
	transition: background-color 0.5s ease-in-out, border 0.5s ease-in-out;
	border: 1.5px solid #1f222b;
	display: flex;
}

.input-icon.error {
	border: 1.5px solid #ff6339;
}

.input-icon.success {
	border: 1.5px solid #2aaafe;
}

.input-field {
	height: 100%;
	width: 100%;
	padding: 0 0 0 20px;
	font-weight: 500;
	font-size: 15px;
	line-height: 18px;
	display: flex;
	align-items: center;
	color: #fff;
	border: none;
	outline: none;
	cursor: text;
	box-sizing: border-box;
	background: none;
	transition: color 0.5s ease-in-out;
}

.input-icon.error .input-field {
	color: #ff6339;
}

.input-field::placeholder {
	color: rgba(162, 163, 172, 0.5);
	opacity: 1;
}

.input-field:-ms-input-placeholder {
	color: rgba(162, 163, 172, 0.5);
}

.input-field::-ms-input-placeholder {
	color: rgba(162, 163, 172, 0.5);
}

.icon-wrap {
	width: 57px;
	height: 100%;
	display: flex;
	cursor: pointer;
}

.icon {
	margin: auto;
	color: #a2a3ac;
	opacity: 0.5;
}

.input-icon.edit .icon {
	color: #a2a3ac;
	opacity: 1;
}

.input-icon.error .icon {
	color: #ff6339;
	opacity: 1;
}

.input-icon.success .icon,
.icon-wrap:hover .icon {
	color: #2aaafe;
	opacity: 1;
}

.input-icon.disabled .icon {
	color: #97ED4C;
	opacity: 1;
}
</style>
