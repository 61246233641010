<template>
	<div class="center-wrap">
		<div class="left-wrap">
			<div class="block" v-if="!mobile">
				<MiniProfile :id="user.id" :avatar="user.avatar" :username="user.name" class="block-profile" />
			</div>
			<div class="block profile-balance">
				<img :src="require('../../assets/icons/balance.svg')" class="balance-icon" />
				<div class="balance-title">{{ t('Profile.balance') }}</div>
				<div class="balance-value">{{ balanceUsd }}</div>
				<DepositBtn :size="24" class="balance-btn" />
			</div>
			<div class="block">
				<div class="block-header">
					<div class="header-title">{{ t('Profile.link') }}</div>
					<a
						href="https://steamcommunity.com/id/me/tradeoffers/privacy#trade_offer_access_url"
						target="_blank"
						class="header-right where-btn"
					>
						<svg width="16" height="16" viewBox="0 0 16 16" fill="none" class="right-icon">
							<g>
								<path
									fill-rule="evenodd"
									clip-rule="evenodd"
									d="M5 2C5.55228 2 6 2.44772 6 3C6 3.51284 5.61396 3.93551 5.11662 3.99327L5 4C4.48716 4 4.06449 4.38604 4.00673 4.88338L4 5V11C4 11.5128 4.38604 11.9355 4.88338 11.9933L5 12H11C11.5128 12 11.9355 11.614 11.9933 11.1166L12 11C12 10.4477 12.4477 10 13 10C13.5523 10 14 10.4477 14 11C14 12.5977 12.7511 13.9037 11.1763 13.9949L11 14H5C3.40232 14 2.09634 12.7511 2.00509 11.1763L2 11V5C2 3.34315 3.34315 2 5 2ZM13 2L13.081 2.003L13.2007 2.02024L13.3121 2.04974L13.4232 2.09367L13.5207 2.146L13.6168 2.21279L13.7071 2.29289L13.8037 2.40469L13.8753 2.51594L13.9063 2.5769L13.9401 2.65835L13.9642 2.73401L13.9931 2.8819L14 3V7C14 7.55228 13.5523 8 13 8C12.4477 8 12 7.55228 12 7V5.414L9.70711 7.70711C9.34662 8.06759 8.77939 8.09532 8.3871 7.7903L8.29289 7.70711C7.93241 7.34662 7.90468 6.77939 8.2097 6.3871L8.29289 6.29289L10.584 4H9C8.44772 4 8 3.55228 8 3C8 2.44772 8.44772 2 9 2H13Z"
									fill="currentColor"
								/>
							</g>
						</svg>
						<div class="right-text">{{ t('Profile.where') }}</div>
					</a>
				</div>
				<InputIcon
					class="input-wrap input-tradelink"
					:placeholder="t('Profile.linkPlaceholder')"
					v-model="tradelink"
					@enter="saveTradelink"
					ref="inputTradelink"
				>
					<img
						v-if="tradelinkSet"
						:src="require('../../assets/icons/check.svg')"
						class="icon check-icon"
					/>
				</InputIcon>
			</div>
		</div>
		<div class="content">
			<div class="content-title">{{ t('Profile.title') }}</div>
			<div class="content-center">
				<!--div class="block mob">
					<TabbedBtn class="tabbed-btn-wrap">
						<router-link custom v-slot="{ navigate, isActive }" to="/profile">
							<div class="btn-item profile-inventory-btn" :class="{ active: isActive }" @click="navigate">
								<div class="item-inner">{{ t('Profile.inventory') }}</div>
							</div>
						</router-link>
						<router-link to="/profile/transactions" custom v-slot="{ navigate, isActive }">
							<div
								class="btn-item profile-transactions-btn"
								:class="{ active: isActive }"
								@click="navigate"
							>
								<div class="item-inner">{{ t('Profile.transactions') }}</div>
							</div>
						</router-link>
					</TabbedBtn>
				</div-->
				<router-view class="view-wrap" :class="{ mobile }" @tradelink-error="tradelinkError" />
			</div>
		</div>
	</div>
</template>
<script lang="ts">
import MiniProfile from '../../components/MiniProfile/index.vue'
import InputIcon from '../../components/InputIcon/index.vue'
import DepositBtn from '../../components/DepositBtn/index.vue'
import { ComponentPublicInstance, computed, defineComponent, reactive, ref, toRefs, watch } from 'vue'
import { useDebounce } from '@/composables/useDebounce'
import { useUserStore } from '@/store/user'
import { useResponsiveStore } from '@/store/responsive'
import { useAPI } from '@/composables/api'
import { useAlertStore } from '@/store/alert'
import { useI18n } from 'vue-i18n'

interface InputIconComponent extends ComponentPublicInstance<HTMLElement> {
	setSuccess(): void
	shakeOnce(): void
}

export default defineComponent({
	components: {
		DepositBtn,
		MiniProfile,
		InputIcon
	},
	setup() {
		const { t } = useI18n()
		const { mobile } = toRefs(useResponsiveStore())
		const alert = useAlertStore()
		const api = useAPI()
		const user = useUserStore()

		const settingsState = reactive({
			tradelink: '',
			lastTradelink: '',
			tradelinkSet: false
		})
		const inputTradelink = ref<InputIconComponent>()

		watch(
			() => user.tradetoken,
			() => {
				if (!user.tradetoken) {
					return
				}

				settingsState.tradelink = `https://steamcommunity.com/tradeoffer/new/?partner=${user.id}&token=${user.tradetoken}`
			},
			{ immediate: true }
		)

		const tradelinkSetDebounce = useDebounce(() => {
			settingsState.tradelinkSet = true
		}, 3000)

		const tradelinkError = () => {
			if (document.querySelector('.input-tradelink') && inputTradelink.value) {
				window.scrollTo(
					0,
					(document.querySelector('.input-tradelink') as HTMLElement).offsetTop - 400
				)

				inputTradelink.value.shakeOnce()
			}
		}

		const saveTradelink = async () => {
			const url = settingsState.tradelink

			if (!url || settingsState.lastTradelink === url) {
				return
			}

			settingsState.lastTradelink = url

			try {
				await api({
					path: '/users/current/tradelink',
					method: 'POST',
					body: { url }
				})

				settingsState.tradelinkSet = true
				if (inputTradelink.value) {
					inputTradelink.value.setSuccess()
				}
				tradelinkSetDebounce()
				user.get()
			} catch (err) {
				alert.pushAlert({
					type: 'danger',
					text: `${t('Profile.errors.save')}: ${err.message}`
				})

				tradelinkError()
			}
		}

		const balanceUsd = computed(() => {
			return `$${user.balanceUsd / 100}`
		})

		return {
			...toRefs(settingsState),
			balanceUsd,
			mobile,
			user,
			inputTradelink,
			tradelinkError,
			saveTradelink,
			t
		}
	}
})
</script>
<style scoped>
@import "../../assets/styles/view.css";

.left-wrap {
	/* background: #1f222b; */
	margin-left: -30px;
	padding: 0 30px 0 30px;
	width: 350px;
	display: unset;
	overflow-y: scroll;
}

.content {
	margin-left: 0;
}

.content-title {
	font-weight: normal;
	font-size: 36px;
	line-height: 30px;
	color: #ffffff;
}

.block:not(.mob) {
	background: #242731;
	border-radius: 10px;
	margin-bottom: 10px;
	padding: 20px;
	box-sizing: border-box;
}

.transition-block {
	transition: all 0.25s ease-in-out;
	max-height: 150px;
	overflow: hidden;
	height: fit-content;
}

.v-enter.transition-block,
.v-leave-to.transition-block {
	max-height: 0px;
	opacity: 0;
}

.block-profile {
	padding: 0;
}

.profile-settings {
	color: #808191;
	cursor: pointer;
}

.profile-settings:hover {
	color: #a2a3ac;
}

.profile-stats-wrap.mobile ::v-deep .profile-stat {
	background: #242731;
	margin-bottom: 10px;
}

.profile-balance {
	display: grid;
	grid-template-columns: 24px 11px 1fr 16px 3px 10px auto 10px 24px;
	padding: 17px 20px !important;
	align-items: center;
}

.balance-icon {
	grid-area: 1 / 1;
}

.balance-title {
	grid-area: 1 / 3;
	font-weight: normal;
	font-size: 14px;
	line-height: 17px;
	color: #a2a3ac;
}

.balance-value {
	grid-area: 1 / 7;
	font-weight: bold;
	font-size: 18px;
	line-height: 22px;
	color: #ffffff;
}

.balance-btn {
	grid-area: 1 / 9;
}

.block-header {
	display: flex;
	margin-bottom: 20px;
}

.header-title {
	font-weight: 500;
	font-size: 18px;
	line-height: 22px;
	color: #ffffff;
}

.header-right {
	display: flex;
	margin: auto 0 auto auto;
	text-decoration: none;
	color: #afb1c0;
}

.right-icon {
	opacity: 0.3;
}

.header-right:hover .right-icon {
	opacity: 1;
}

.right-text {
	margin-left: 5px;
	font-weight: normal;
	font-size: 13px;
	line-height: 16px;
	color: #afb1c0;
}

.header-right:hover .right-text {
	color: #fff;
}

.copy-icon {
	cursor: pointer;
}

.ref-stats-wrap {
	margin-top: 20px;
}

.input-label {
	font-size: 13px;
	line-height: 16px;
	color: #afb1c0;
	margin-top: 30px;
}

.content-center {
	background: linear-gradient(
		180deg,
		rgba(31, 33, 42, 0.5) 0%,
		rgba(36, 39, 49, 0.5) 15%
	);
	margin: 30px -30px -30px -30px;
	padding: 30px 30px 0 30px;
	display: flex;
	min-height: 0;
	flex-direction: column;
}

.view-wrap {
	/* margin-top: 20px; */
	display: flex;
	flex: 1;
	/* height: calc(100vh - 424px); */
}

@media (max-width: 950px) {
	.center-wrap {
		height: fit-content;
	}

	.left-wrap {
		background: none;
		width: 100%;
		margin-left: unset;
		padding: 0 10px 0px 10px;
	}

	.block-profile {
		max-width: 100%;
		width: 100%;
	}

	.view-wrap {
		height: 100%;
		overflow-x: hidden;
	}

	.input-wrap {
		height: 42px;
		border-radius: 12px;
	}

	.content-center {
		background: none;
		margin: 10px 0 0 0;
		padding: 0;
	}

	.block:not(.mob) {
		border-radius: 10px;
		box-sizing: border-box;
		background: none;
		border: 1px solid rgba(175, 177, 192, 0.1);
	}

	.block.mob {
		padding: 0;
		background: none;
	}

	.tabbed-btn-wrap {
		width: 100%;
		background: #1A1B23;
	}

	.view-wrap {
		/* margin-top: 10px; */
	}

	.content-title {
		display: none;
	}

	.content {
		background: #1E2029;
	}
}

@media (max-width: 500px) {
	.block-profile ::v-deep .profile-image {
		display: none;
	}

	.block-profile ::v-deep .profile-left {
		margin: auto 20px auto 0px;
	}
}

@media (max-width: 950px) {
	.input-label-wrap ::v-deep .input-field:focus,
	.input-wrap ::v-deep .input-field:focus {
		font-size: 16px;
	}
}
</style>
