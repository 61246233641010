
import MiniProfile from '../../components/MiniProfile/index.vue'
import InputIcon from '../../components/InputIcon/index.vue'
import DepositBtn from '../../components/DepositBtn/index.vue'
import { ComponentPublicInstance, computed, defineComponent, reactive, ref, toRefs, watch } from 'vue'
import { useDebounce } from '@/composables/useDebounce'
import { useUserStore } from '@/store/user'
import { useResponsiveStore } from '@/store/responsive'
import { useAPI } from '@/composables/api'
import { useAlertStore } from '@/store/alert'
import { useI18n } from 'vue-i18n'

interface InputIconComponent extends ComponentPublicInstance<HTMLElement> {
	setSuccess(): void
	shakeOnce(): void
}

export default defineComponent({
	components: {
		DepositBtn,
		MiniProfile,
		InputIcon
	},
	setup() {
		const { t } = useI18n()
		const { mobile } = toRefs(useResponsiveStore())
		const alert = useAlertStore()
		const api = useAPI()
		const user = useUserStore()

		const settingsState = reactive({
			tradelink: '',
			lastTradelink: '',
			tradelinkSet: false
		})
		const inputTradelink = ref<InputIconComponent>()

		watch(
			() => user.tradetoken,
			() => {
				if (!user.tradetoken) {
					return
				}

				settingsState.tradelink = `https://steamcommunity.com/tradeoffer/new/?partner=${user.id}&token=${user.tradetoken}`
			},
			{ immediate: true }
		)

		const tradelinkSetDebounce = useDebounce(() => {
			settingsState.tradelinkSet = true
		}, 3000)

		const tradelinkError = () => {
			if (document.querySelector('.input-tradelink') && inputTradelink.value) {
				window.scrollTo(
					0,
					(document.querySelector('.input-tradelink') as HTMLElement).offsetTop - 400
				)

				inputTradelink.value.shakeOnce()
			}
		}

		const saveTradelink = async () => {
			const url = settingsState.tradelink

			if (!url || settingsState.lastTradelink === url) {
				return
			}

			settingsState.lastTradelink = url

			try {
				await api({
					path: '/users/current/tradelink',
					method: 'POST',
					body: { url }
				})

				settingsState.tradelinkSet = true
				if (inputTradelink.value) {
					inputTradelink.value.setSuccess()
				}
				tradelinkSetDebounce()
				user.get()
			} catch (err) {
				alert.pushAlert({
					type: 'danger',
					text: `${t('Profile.errors.save')}: ${err.message}`
				})

				tradelinkError()
			}
		}

		const balanceUsd = computed(() => {
			return `$${user.balanceUsd / 100}`
		})

		return {
			...toRefs(settingsState),
			balanceUsd,
			mobile,
			user,
			inputTradelink,
			tradelinkError,
			saveTradelink,
			t
		}
	}
})
